import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const NAGIVATION_HEIGHT = 115;

export default function AJBUDNavbar(props) {
  const [glut, setGlut] = useState(0);
  const callback = () => {
    if (window !== undefined) {
      let wh = window.scrollY;
      setGlut(wh / 10);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", callback);
    return () => {
      window.removeEventListener("scroll", callback);
    };
  }, []);
  const navigateTo = (ref) => {
    if (ref.current !== null) {
      const targetLocation = window.scrollY + ref.current.getBoundingClientRect().top - NAGIVATION_HEIGHT;
      window.scroll({ top: targetLocation, behavior: "smooth"})
    }
  };
  
  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      expand="lg"
      variant="light"
      style={{
        padding: 0,
        margin: 0,
        background: `linear-gradient(45deg, black 120px+${Math.min(
          glut,
          100
        )}%, #20212590 ${Math.min(50 + glut, 100)}%, transparent)`,
      }}
    >
      <Container style={{ padding: 0, margin: 0 }}>
        <Navbar.Brand href="#" className="bg-red">
          <img src="logo192.png" width={90} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" 
        style={{backgroundColor: 'white', color: 'transparent', marginRight: 12}}/>
        <Navbar.Collapse id="navbarScroll" >
          <Nav
            className="me-auto my-2 my-lg-0"
          >
            <Nav.Link
              href="#"
              style={{ color: "white" }}
              onClick={() => {
                navigateTo(props.aboutRef);
              }}
            >
              O nas
            </Nav.Link>
            {/* <Nav.Link
              href="#"
              style={{ color: "white" }}
              onClick={() => {
                navigateTo(props.reviewRef);
              }}
            >
              Opinie klientów
            </Nav.Link> */}
            <Nav.Link
              href="#"
              style={{ color: "white" }}
              onClick={() => {
                navigateTo(props.offerRef);
              }}
            >
              Oferta
            </Nav.Link>
            <Nav.Link
              href="#"
              style={{ color: "white" }}
              onClick={() => {
                navigateTo(props.projectsRef);
              }}
            >
              Realizacje
            </Nav.Link>
            <Nav.Link
              href="#"
              style={{ color: "white" }}
              onClick={() => {
                navigateTo(props.contactRef);
              }}
            >
              Kontakt
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

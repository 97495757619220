import React from "react";
import { Carousel } from "react-bootstrap";
import "./App.css";

export default function AJBUDCarousel() {
  const data = [
    {
      image: "img/carousel1.jpg",
      /*title: "Realizacja",
      subtitle: "Nulla vitae elit libero, a pharetra augue mollis interdum.",*/
    },
    {
      image: "img/carousel2.jpg",
      /*title: "Realizacja",
      subtitle: "Nulla vitae elit libero, a pharetra augue mollis interdum.",*/
    },
    {
      image: "img/carousel3.jpg",
      /*title: "Realizacja",
      subtitle: "Nulla vitae elit libero, a pharetra augue mollis interdum.",*/
    },
    
  ];

  return (
    <Carousel variant="dark" fade>
      {data.map((element, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={element.image}
            alt="First slide"
          />
          <Carousel.Caption>
            <h1>{element.title}</h1>
            <p>{element.subtitle}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

import React, { useRef } from "react";
import AJBUDCarousel from "./AJBUDCarousel";
import AJBUDAbout from "./AJBUDAbout";
import AJBUDNavbar from "./AJBUDNavbar";
import "./App.css";

const App = () => {
  const offerRef = useRef();
  const aboutRef = useRef();
  const projectsRef = useRef();
  const reviewRef = useRef();
  const contactRef = useRef();

  return (
    <div className="App">
      <AJBUDNavbar
        offerRef={offerRef}
        aboutRef={aboutRef}
        projectsRef={projectsRef}
        reviewRef={reviewRef}
        contactRef={contactRef}
      />
      <AJBUDCarousel />
      <AJBUDAbout
        offerRef={offerRef}
        aboutRef={aboutRef}
        projectsRef={projectsRef}
        reviewRef={reviewRef}
        contactRef={contactRef}
      />
    </div>
  );
};

export default App;

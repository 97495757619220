import React, { useState } from "react";
import { PhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const AJBUDGallery = () => {
  const photos = [
    {
      src: "./img/elewacja1.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja2.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja2a.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja3.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja4.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja4a.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja4b.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja4c.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja4d.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja5.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja7.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja8.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja9.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja10.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja10a.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja10b.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja10c.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "./img/elewacja12.jpg",
      width: 400,
      height: 300,
    },
  ];
  const [index, setIndex] = useState(-1);
  const slides = photos.map(({ src, width, height, images }) => {
    return {
      src,
    };
  });

  return (
    <>
      <PhotoAlbum
        photos={photos}
        layout="rows"
        onClick={({ index }) => setIndex(index)}
      />

      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Zoom, Fullscreen, Thumbnails, Slideshow]}
      />
    </>
  );
};

export default AJBUDGallery;

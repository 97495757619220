import React from "react";
import "./App.css";
import AJBUDGallery from "./AJBUDGallery";
// import AJBUDReviews from "./AJBUDReviews";
export default function AJBUDAbout(props) {
  return (
    <div className="AJBUDAbout text-center">
      <div className="AboutText">
        <div ref={props.aboutRef} style={{ padding: 30 }}>
          <h1 className="AJBUDAbout_header"> AJ Vision </h1>
          <br />
          <p>
            AJ Vision to firma budowlana, która specjalizuje się w generalnym
            wykonawstwie budynków użyteczności publicznej a także budownictwa
            komercyjnego, przemysłowego, mieszkaniowego. Firma zapewnia
            kompleksową obsługę na wszystkich etapach procesu budowlanego, od
            projektu po wykonanie.
          </p>
          <p>
            Dzięki swojemu doświadczeniu i&nbsp;wysokiej jakości usług, firma
            zyskała zaufanie wielu zadowolonych klientów. AJ Vision stawia na
            profesjonalizm oraz terminowość wykonania zleceń, co pozwala na
            osiągnięcie wysokiej jakości końcowej realizacji inwestycji.
          </p>
        </div>
        {/* <div
          ref={props.reviewRef}
          style={{ backgroundColor: "#333", padding: 30 }}
        >
          <h2 className="H2About">Mówią o nas</h2>
          <AJBUDReviews />
        </div> */}
        <div style={{ padding: 30 }}>
          <div ref={props.offerRef}>
            <br />
            <h2 className="H2About">Oferta</h2>
          </div>
          <ul>
            <li>
              Generalne wykonawstwo budynków użyteczności publicznej,
              budownictwa komercyjnego, przemysłowego i&nbsp;mieszkaniowego
            </li>
            <li>
              Prace elewacyjne: elewacje zewnętrzne budynków, termomodernizacja,
              ocieplenia, renowacja elewacji
            </li>
            <li>
              Prace wykończeniowe: sufity podwieszane z materiałów takich jak
              G/K, OWA, Armstrong, Barwa System, roboty posadzkarskie i
              tynkarskie, ściany działowe murowane lub&nbsp;G/K
            </li>
            <li>Instalacje: elektryczne, wod-kan, wentylacji i klimatyzacji</li>
          </ul>
        </div>
      </div>
      <div
        ref={props.projectsRef}
        style={{ backgroundColor: "#333", padding: 30 }}
      >
        <h2 className="H2About">Realizacje</h2>
        <AJBUDGallery />
      </div>
      <div style={{ padding: 30 }} ref={props.contactRef}>
        <ul className="NoBullets" style={{ textAlign: "start" }}>
          <li>
            <b>AJ Vision Sp. z o.o.</b>
          </li>
          <li>
            <a href="tel:+48697166361">+ 48 697 166 361</a>
          </li>
          <li>
            <a href="mailto:biuoro@ajvision.com.pl">biuro@ajvision.com.pl</a>
          </li>
          <li>
            ul. Chmielna 2/31
            <br />
            00-020 Warszawa
          </li>
          <br />
          <li>NIP 525-294-69-08</li>
        </ul>
      </div>
      Copyright &copy; Anna Budzińska
    </div>
  );
}
